'use client';

import React, { useContext, useEffect, useState } from 'react';
import { useTranslations } from 'next-intl';
import cx from 'classnames';
import useSWR from 'swr';
import { getTopChannels } from 'serverActions/home';
import { MetricType, Period, ValueType } from 'business/channel/repository/types.d';
import { Link } from 'utils/navigation';
import Menu from 'components/Menu';
import Paper from 'components/Paper';
import Button from 'components/Button';
import Divider from 'components/Divider';
import Typography from 'components/Typography';
import ContentHeadline from 'components/ContentHeadline';
import Dropdown from 'components/Button/Dropdown';
import { ConfigurationContext } from 'contexts/ConfigurationContext';
import { ReactComponent as UserIcon } from 'public/assets/icons/user.svg';
import { ReactComponent as EyeIcon } from 'public/assets/icons/eye.svg';
import { ReactComponent as UserPlusIcon } from 'public/assets/icons/user-plus.svg';
import { ReactComponent as RightArrowIcon } from 'public/assets/icons/arrow-r.svg';
import { ReactComponent as ArrowDownIcon } from 'public/assets/icons/arrow-down.svg';
import ChannelCard from './ChannelCard';
import ChannelsListPlaceholder from './Placeholder';
import styles from './TopChannels.module.scss';
interface TopChannelsProps {
  initialValues?: Awaited<ReturnType<typeof getTopChannels>>;
  additionalFilters?: Record<string, string>;
  uniqueKey: string;
  className?: string;
  predefineGeo?: string;
  ignoreGeo?: boolean;
}
const TopChannels: React.FC<TopChannelsProps> = ({
  initialValues,
  additionalFilters,
  uniqueKey,
  predefineGeo,
  ignoreGeo = false,
  className
}) => {
  const t = useTranslations();
  const [initiated, setInitiated] = useState(!initialValues);
  const {
    countriesFilter
  } = useContext(ConfigurationContext);
  const [selectedPeriod, setSelectedPeriod] = useState<{
    label: string;
    value: string;
  }>({
    label: t('Channel.stats.day_relative'),
    value: Period.Day
  });
  const {
    data: channels = initialValues,
    isLoading
  } = useSWR(`top-channels/${uniqueKey}/${selectedPeriod.value}/${predefineGeo || countriesFilter}`, () => getTopChannels(selectedPeriod.value, {
    ...additionalFilters,
    ...(countriesFilter && !ignoreGeo ? {
      location: predefineGeo || countriesFilter
    } : {})
  }), {
    revalidateOnFocus: false,
    revalidateIfStale: false,
    revalidateOnReconnect: false
  });
  useEffect(() => {
    if (!initiated && channels) {
      setInitiated(true);
    }
  }, [initiated, channels]);
  return <div className={cx(styles.container, className)} data-sentry-component="TopChannels" data-sentry-source-file="TopChannels.tsx">
      <div className={styles.title}>
        <Typography variant="h2bk" align="center" data-sentry-element="Typography" data-sentry-source-file="TopChannels.tsx">
          {t('list.topChannels.title')}{' '}
          <Typography variant="h2bk" color="brand" className={styles.period} data-sentry-element="Typography" data-sentry-source-file="TopChannels.tsx">
            {/* @ts-ignore */}
            {t(`list.topChannels.period.${selectedPeriod.value.toLowerCase()}`)}
            <Dropdown trigger={<Button variant="secondary" size="small" rounded className={styles.trigger}>
                  <ArrowDownIcon />
                </Button>} dropdown={<Menu items={[{
            label: t('Channel.stats.day_relative'),
            value: Period.Day
          }, {
            label: t('Channel.stats.week_relative'),
            value: Period.Week
          }, {
            label: t('Channel.stats.month_relative'),
            value: Period.Month
          }]}
          // @ts-ignore
          onSelect={setSelectedPeriod} selected={selectedPeriod} />} data-sentry-element="Dropdown" data-sentry-source-file="TopChannels.tsx" />
          </Typography>
        </Typography>
      </div>
      <div className={styles.root}>
        <div className={styles.top}>
          <ContentHeadline data-sentry-element="ContentHeadline" data-sentry-source-file="TopChannels.tsx">{t('list.topChannels.subscribers.title')}</ContentHeadline>
          <Paper data-sentry-element="Paper" data-sentry-source-file="TopChannels.tsx">
            {initiated && isLoading ? <ChannelsListPlaceholder /> : <div className={styles.list}>
                {(!channels?.membersTop || channels?.membersTop?.length === 0) && <div className={styles.empty}>
                    <Typography variant="h6sb">{t('list.topChannels.empty')}</Typography>
                  </div>}
                {channels?.membersTop?.map(channel => <ChannelCard key={channel.id} channel={channel} icon={<UserIcon />} metric={channel.metrics[MetricType.MembersCount].absolute} members={channel.metrics[MetricType.MembersCount].absolute} hideMembersCount />)}
              </div>}
            <Divider data-sentry-element="Divider" data-sentry-source-file="TopChannels.tsx" />
            <Button variant="primary" href={{
            pathname: '/channels',
            query: {
              sort: MetricType.MembersCount,
              order: 'desc',
              period: selectedPeriod.value.replace('_relative', ''),
              ...{
                ...additionalFilters,
                ...(countriesFilter ? {
                  location: countriesFilter
                } : {})
              }
            }
          }} className={styles.button} component={Link} data-sentry-element="Button" data-sentry-source-file="TopChannels.tsx">
              {t('Home.showAll')} <RightArrowIcon data-sentry-element="RightArrowIcon" data-sentry-source-file="TopChannels.tsx" />
            </Button>
          </Paper>
        </div>
        <div className={styles.top}>
          <ContentHeadline data-sentry-element="ContentHeadline" data-sentry-source-file="TopChannels.tsx">{t('list.topChannels.subscribersGrow.title')}</ContentHeadline>
          <Paper data-sentry-element="Paper" data-sentry-source-file="TopChannels.tsx">
            {initiated && isLoading ? <ChannelsListPlaceholder /> : <div className={styles.list}>
                {(!channels?.membersGrowthTop || channels?.membersGrowthTop?.length === 0) && <div className={styles.empty}>
                    <Typography variant="h6sb">{t('list.topChannels.empty')}</Typography>
                  </div>}
                {channels?.membersGrowthTop?.map(channel => <ChannelCard key={channel.id} channel={channel} icon={<UserPlusIcon />} metric={channel.metrics[MetricType.MembersCount].change} members={channel.metrics[MetricType.MembersCount].absolute} highlightMetric />)}
              </div>}
            <Divider data-sentry-element="Divider" data-sentry-source-file="TopChannels.tsx" />
            <Button variant="primary" href={{
            pathname: '/channels',
            query: {
              sort: MetricType.MembersCount,
              order: 'desc',
              sortValue: ValueType.Change,
              period: selectedPeriod.value.replace('_relative', ''),
              ...{
                ...additionalFilters,
                ...(countriesFilter ? {
                  location: countriesFilter
                } : {})
              }
            }
          }} className={styles.button} component={Link} data-sentry-element="Button" data-sentry-source-file="TopChannels.tsx">
              {t('Home.showAll')} <RightArrowIcon data-sentry-element="RightArrowIcon" data-sentry-source-file="TopChannels.tsx" />
            </Button>
          </Paper>
        </div>
        <div className={styles.top}>
          <ContentHeadline data-sentry-element="ContentHeadline" data-sentry-source-file="TopChannels.tsx">{t('list.topChannels.postsViews.title')}</ContentHeadline>
          <Paper className={styles.paper} data-sentry-element="Paper" data-sentry-source-file="TopChannels.tsx">
            {initiated && isLoading ? <ChannelsListPlaceholder /> : <div className={styles.list}>
                {(!channels?.postsViewsTop || channels?.postsViewsTop?.length === 0) && <div className={styles.empty}>
                    <Typography variant="h6sb">{t('list.topChannels.empty')}</Typography>
                  </div>}
                {channels?.postsViewsTop?.map(channel => <ChannelCard key={channel.id} channel={channel} icon={<EyeIcon />} metric={channel.metrics[MetricType.PostsViewAvgCount].absolute} members={channel.metrics[MetricType.MembersCount].absolute} />)}
              </div>}
            <Divider data-sentry-element="Divider" data-sentry-source-file="TopChannels.tsx" />
            <Button variant="primary" href={{
            pathname: '/channels',
            query: {
              sort: 'PostsViewAvgCount',
              order: 'desc',
              period: selectedPeriod.value.replace('_relative', ''),
              ...{
                ...additionalFilters,
                ...(countriesFilter ? {
                  location: countriesFilter
                } : {})
              }
            }
          }} component={Link} className={styles.button} data-sentry-element="Button" data-sentry-source-file="TopChannels.tsx">
              {t('Home.showAll')} <RightArrowIcon data-sentry-element="RightArrowIcon" data-sentry-source-file="TopChannels.tsx" />
            </Button>
          </Paper>
        </div>
      </div>
    </div>;
};
export default TopChannels;