'use client';

import React, { useContext } from 'react';
import { useTranslations } from 'next-intl';
import useSWR, { SWRConfiguration } from 'swr';
import { TTotalStatsResponse } from 'business/catalogue/repository/types.d';
import Paper from 'components/Paper';
import Button from 'components/Button';
import Divider from 'components/Divider';
import Typography from 'components/Typography';
import HeadlineWithInfo from 'components/HeadlineWithInfo';
import { Link } from 'utils/navigation';
import formatThousands from 'utils/formatThousands';
import { ConfigurationContext } from 'contexts/ConfigurationContext';
import { getTotalStats } from 'serverActions/home';
import styles from './Catalogs.module.scss';
const renderStat = (value: number, isLoading: boolean) => <Typography variant="h5" color="primary" data-sentry-element="Typography" data-sentry-component="renderStat" data-sentry-source-file="Catalogs.tsx">
    {isLoading ? <div className={styles.placeholder} /> : formatThousands(value)}
  </Typography>;
const Catalogs: React.FC<{
  stats: SWRConfiguration<TTotalStatsResponse>['fallback'];
}> = ({
  stats
}) => {
  const t = useTranslations('Home');
  const {
    countriesFilter
  } = useContext(ConfigurationContext);
  const {
    data,
    isLoading
  } = useSWR(`/catalogue/total-stats/${countriesFilter}`, () => getTotalStats(countriesFilter), {
    fallback: stats,
    revalidateOnMount: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false
  });
  return <div className={styles.root} data-sentry-component="Catalogs" data-sentry-source-file="Catalogs.tsx">
      <div className={styles.item}>
        <Typography variant="h6" className={styles.title} data-sentry-element="Typography" data-sentry-source-file="Catalogs.tsx">
          {t('channels.title')}
        </Typography>
        <Paper data-sentry-element="Paper" data-sentry-source-file="Catalogs.tsx">
          <div className={styles.metric}>
            <HeadlineWithInfo data-sentry-element="HeadlineWithInfo" data-sentry-source-file="Catalogs.tsx">{t('channels.total')}</HeadlineWithInfo>
            {renderStat(data?.channelsCount ?? 0, isLoading)}
          </div>
          <Divider className={styles.divider} data-sentry-element="Divider" data-sentry-source-file="Catalogs.tsx" />
          <div className={styles.metric}>
            <HeadlineWithInfo data-sentry-element="HeadlineWithInfo" data-sentry-source-file="Catalogs.tsx">{t('channels.record')}</HeadlineWithInfo>
            {renderStat(data?.subscribersMaxCount ?? 0, isLoading)}
          </div>
        </Paper>
        <Paper className={styles.action} data-sentry-element="Paper" data-sentry-source-file="Catalogs.tsx">
          <Button href="/channels" component={Link} size="large" className={styles.link} variant="secondary" data-sentry-element="Button" data-sentry-source-file="Catalogs.tsx">
            {t('catalogue.channels')}
          </Button>
        </Paper>
      </div>
      <div className={styles.item}>
        <Typography variant="h6" className={styles.title} data-sentry-element="Typography" data-sentry-source-file="Catalogs.tsx">
          {t('posts.title')}
        </Typography>
        <Paper data-sentry-element="Paper" data-sentry-source-file="Catalogs.tsx">
          <div className={styles.metric}>
            <HeadlineWithInfo data-sentry-element="HeadlineWithInfo" data-sentry-source-file="Catalogs.tsx">{t('posts.record')}</HeadlineWithInfo>
            {renderStat(data?.postsCount ?? 0, isLoading)}
          </div>
          <Divider className={styles.divider} data-sentry-element="Divider" data-sentry-source-file="Catalogs.tsx" />
          <div className={styles.metric}>
            <HeadlineWithInfo data-sentry-element="HeadlineWithInfo" data-sentry-source-file="Catalogs.tsx">{t('posts.total')}</HeadlineWithInfo>
            {renderStat(data?.postsViewMaxCount ?? 0, isLoading)}
          </div>
        </Paper>
        <Paper className={styles.action} data-sentry-element="Paper" data-sentry-source-file="Catalogs.tsx">
          <Button href="/posts" component={Link} size="large" className={styles.link} variant="secondary" data-sentry-element="Button" data-sentry-source-file="Catalogs.tsx">
            {t('catalogue.posts')}
          </Button>
        </Paper>
      </div>
    </div>;
};
export default Catalogs;