import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/[locale]/components/Catalogs/Catalogs.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/[locale]/components/Categories/Categories.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/[locale]/components/GeneralInfo/GeneralInfo.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/[locale]/components/Map/Map.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/[locale]/components/PopularPosts/PopularPosts.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/[locale]/components/Search/Search.tsx");
;
import(/* webpackMode: "eager" */ "/app/app/[locale]/page.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/Promotion/Promotion.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/Typography/Typography.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/features/ChannelsListInfinite/ChannelsListInfinite.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/features/Country/Button/Button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/features/Footer/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/features/LoginPanel/LoginPanel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/features/Promotions/ClientSidePromotions.tsx");
;
import(/* webpackMode: "eager" */ "/app/features/Promotions/Promotions.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/features/TopChannels/TopChannels.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
