'use client';

import React from 'react';
import { useTranslations } from 'next-intl';
import Typography from 'components/Typography';
import SearchComponent from 'features/Search';
import styles from './Search.module.scss';
const Search: React.FC = () => {
  const t = useTranslations('Home');
  return <div className={styles.root} data-sentry-component="Search" data-sentry-source-file="Search.tsx">
      <Typography variant="h2bk" className={styles.title} align="center" data-sentry-element="Typography" data-sentry-source-file="Search.tsx">
        {t('search.title')}
      </Typography>
      <SearchComponent minimized animated={false} data-sentry-element="SearchComponent" data-sentry-source-file="Search.tsx" />
    </div>;
};
export default Search;