'use client';

import React, { useEffect } from 'react';
import { useParams, useRouter } from 'next/navigation';
import cx from 'classnames';
import Paper from 'components/Paper';
import Divider from 'components/Divider';
import * as Post from 'components/PostContentParts';
import { TPost } from 'business/channel/repository';
import styles from './VerticalWithEmoji.module.scss';
interface VerticalWithEmoji {
  post: TPost & {
    channelId: string;
    highlight?: string;
  };
  withBlurredStyle?: boolean;
  textLineClamp?: number;
  withPaper?: boolean;
  classes?: {
    root?: string;
    post?: string;
  };
}
const VerticalWithEmoji: React.FC<VerticalWithEmoji> = ({
  post,
  withBlurredStyle = true,
  textLineClamp = 4,
  withPaper = false,
  classes = {}
}) => {
  const params = useParams();
  const router = useRouter();
  const [buttonRef, setButtonRef] = React.useState<HTMLDivElement | null>(null);
  useEffect(() => {
    // prevent modal opening. Because link nested in button
    if (buttonRef) {
      buttonRef.querySelectorAll(`a`).forEach(el => {
        el.addEventListener('click', e => {
          e.stopPropagation();
        });
      });
    }
  }, [buttonRef]);
  const handleOpenPost = () => {
    router.push(`/channel/${post.channelId || params.id}/${post.id}`, {
      scroll: false
    });
  };
  const ComponentWrapper = withPaper ? Paper : 'div';
  return <Post.Root post={post} data-sentry-element="unknown" data-sentry-component="VerticalWithEmoji" data-sentry-source-file="VerticalWithEmoji.tsx">
      {({
      hasGallery,
      noContentExceptGallery,
      isDeleted,
      isRepost,
      isRestricted
    }) => <ComponentWrapper className={cx(styles.container, classes?.root, {
      [styles.paper]: withPaper
    })}>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div ref={setButtonRef} onClick={handleOpenPost} className={cx(styles.post, classes?.post, {
        [styles.blurred]: withBlurredStyle,
        [styles.restricted]: isRestricted
      })}>
            {isRestricted ? <Post.Restricted src={process.env.NEXT_PUBLIC_RESTRICTED_VERTICAL_IMAGE as string} /> : <>
                <Post.DeletedInfo classes={{
            root: styles.deleted
          }} />
                {isDeleted && (!hasGallery || isRepost) && <Divider />}
                <Post.RepostInfo />
                {isRepost && !hasGallery && <Divider />}
                <Post.Gallery previewCount={3} moreMediaProps={{
            typographyVariant: 'h8'
          }} className={styles.gallery}>
                  <Post.ChannelInfo isDark className={styles.channelInfoInGallery} />
                  <Post.DateTimeWithActions className={styles.dateTimeInGallery} />
                </Post.Gallery>
                {!noContentExceptGallery && <>
                    {!hasGallery && <Post.ChannelInfo className={styles.channelInfo} />}
                    <div className={styles.content}>
                      {!hasGallery && <Post.DateTimeWithActions />}
                      <div className={styles.textContainer}>
                        <Post.TextContent lineClamp={textLineClamp} />
                      </div>
                      <div className={styles.sticker}>
                        <Post.Sticker />
                      </div>
                      <Post.Documents className={styles.documents} />
                      <div className={styles.centered}>
                        <Post.Audio />
                        <Post.VideoCircle className={styles.circleVideo} />
                        <Post.UnsupportedMessage />
                      </div>
                    </div>
                    <Divider className={styles.divider} />
                  </>}
                {noContentExceptGallery && <div className={styles.expand} />}
                <Post.Metrics className={styles.metrics} />
              </>}
          </div>
        </ComponentWrapper>}
    </Post.Root>;
};
export default VerticalWithEmoji;