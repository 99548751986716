import React from 'react';
import styles from './Placeholder.module.scss';
const Placeholder: React.FC = () => {
  return <div className={styles.root} data-sentry-component="Placeholder" data-sentry-source-file="Placeholder.tsx">
      {Array(10).fill(null).map((_, index) =>
    // eslint-disable-next-line react/no-array-index-key
    <div className={styles.item} key={index}>
            <div className={styles.channel}>
              <div className={styles.avatar} />
              <div className={styles.title} />
              <div className={styles.details}>
                <div className={styles.members} />
                <div className={styles.category} />
              </div>
            </div>
            <div className={styles.metric} />
          </div>)}
    </div>;
};
export default Placeholder;