'use client';

import React, { HTMLAttributes, useMemo } from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import InputAdornment from '@mui/material/InputAdornment';
import { useTranslations } from 'next-intl';
// @ts-ignore
import shortNumber from 'short-number';
import Paper from 'components/Paper';
import Button from 'components/Button';
import Text from 'components/Input/Text';
import Divider from 'components/Divider';
import Typography from 'components/Typography';
import { Link } from 'utils/navigation';
import { ReactComponent as SearchIcon } from 'public/assets/icons/search.svg';
import { ReactComponent as RightArrowIcon } from 'public/assets/icons/arrow-r.svg';
import { getRegionsWithCities } from 'serverActions/home';
import styles from './List.module.scss';
interface CitiesListProps extends HTMLAttributes<HTMLDivElement> {
  cities: Awaited<ReturnType<typeof getRegionsWithCities>>;
}
const filterCities = (cities: (CitiesListProps['cities']['0'] & {
  translation: string;
})[]) => (search: string) => {
  if (!search) return cities;
  return cities.filter(city => city.translation.toLowerCase().includes(search.toLowerCase()));
};
const CitiesList: React.FC<CitiesListProps> = ({
  cities,
  className
}) => {
  const t = useTranslations();
  const getFilteredCities = useMemo(() => filterCities(
  // @ts-ignore
  cities.map(c => ({
    ...c,
    translation: t(`${c.isRegion ? 'region' : 'city'}.uk.${c.iso}`)
  }))), [cities, t]);
  return <Paper className={className} data-sentry-element="Paper" data-sentry-component="CitiesList" data-sentry-source-file="List.tsx">
      <div className={styles.root}>
        <Typography variant="h10" className={styles.title} data-sentry-element="Typography" data-sentry-source-file="List.tsx">
          {t('Home.cities.list.title')}
        </Typography>
        <Form onSubmit={() => {}} subscription={{
        submitting: true
      }} data-sentry-element="Form" data-sentry-source-file="List.tsx">
          {() => <>
              <Field name="search" component={Text} placeholder={t('Home.cities.searchPlaceholder')} classes={{
            root: styles.search,
            input: styles.searchInput
          }} InputProps={{
            startAdornment: <InputAdornment position="start" disablePointerEvents={false} className={styles.adornment}>
                      <SearchIcon className={styles.searchIcon} />
                    </InputAdornment>
          }} />
              <div className={styles.list}>
                <FormSpy subscription={{
              values: true
            }}>
                  {({
                values
              }) => getFilteredCities(values?.search).map(city => <Link href={{
                // @ts-ignore
                pathname: city.isRegion ? '/region/[id]' : '/city/[id]',
                params: {
                  id: city.iso
                }
              }} key={city.id} className={styles.city}>
                        <Typography variant="h10bk">
                          {/* @ts-ignore */}
                          {t(`${city.isRegion ? 'region' : 'city'}.uk.${city.iso}`)}
                        </Typography>
                        <div className={styles.channels}>
                          <Typography variant="h12bk" color="white">
                            {shortNumber(city.channelsCount)}
                          </Typography>
                        </div>
                      </Link>)}
                </FormSpy>
              </div>
            </>}
        </Form>
        <Divider data-sentry-element="Divider" data-sentry-source-file="List.tsx" />
        <Button href="/cities" variant="text" size="medium" className={styles.button} component={Link} data-sentry-element="Button" data-sentry-source-file="List.tsx">
          {t('Home.showAll')}
          <RightArrowIcon data-sentry-element="RightArrowIcon" data-sentry-source-file="List.tsx" />
        </Button>
      </div>
    </Paper>;
};
export default CitiesList;