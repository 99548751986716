'use client';

import React, { useContext } from 'react';
import { useTranslations } from 'next-intl';
import { Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import Image from 'next/image';
import { getCountries } from 'serverActions/application';
import Button from 'components/Button';
import Typography from 'components/Typography';
import CountriesList from 'features/Filters/Country/CountriesList';
import DropDownButton from 'components/Button/Dropdown/Dropdown';
import { ConfigurationContext } from 'contexts/ConfigurationContext';
import { TCountry } from 'business/application/repository/types.d';
import { ReactComponent as DownIcon } from 'public/assets/icons/arrow-down.svg';
import by from 'public/assets/flags/by.png';
import cn from 'public/assets/flags/cn.png';
import et from 'public/assets/flags/et.png';
import global from 'public/assets/flags/global.png';
import ind from 'public/assets/flags/in.png';
import ir from 'public/assets/flags/ir.png';
import kaz from 'public/assets/flags/kaz.png';
import kg from 'public/assets/flags/kg.png';
import ru from 'public/assets/flags/ru.png';
import uk from 'public/assets/flags/uk.png';
import uz from 'public/assets/flags/uz.png';
import styles from './Button.module.scss';
const flagsMap = {
  by,
  cn,
  et,
  global,
  in: ind,
  ir,
  kaz,
  kg,
  ru,
  uk,
  uz
};
const getFlag = (country: (string | null)[]) => {
  if (country.length === 1) {
    const singleCountry = country.at(0);
    return flagsMap[singleCountry as keyof typeof flagsMap] ? flagsMap[singleCountry as keyof typeof flagsMap] : flagsMap.global;
  }
  return flagsMap.global;
};
interface CountryButtonProps {
  countries: Awaited<ReturnType<typeof getCountries>>;
  override?: (string | null)[];
}
const CountryButton: React.FC<CountryButtonProps> = ({
  countries,
  override
}) => {
  const t = useTranslations();
  const {
    setCountry,
    country
  } = useContext(ConfigurationContext);
  const getTitle = (selectedCountries: (string | null)[]) => {
    const selectedCountriesTitles = selectedCountries.map(country =>
    // @ts-ignore
    t(`country.inPrefix.${country}`));
    return selectedCountriesTitles.length > 2 ? `${selectedCountriesTitles.slice(0, 2).join(', ')}, +${selectedCountriesTitles.length - 2}` : selectedCountriesTitles.join(', ');
  };
  return <Form<{
    location: (string | null)[];
  }> onSubmit={() => {}} subscription={{
    values: true
  }} initialValues={{
    location: override || country || []
  }} data-sentry-element="Form" data-sentry-component="CountryButton" data-sentry-source-file="Button.tsx">
      {({
      values,
      form
    }) => <>
          <OnChange name="location">
            {(value: (string | null)[]) => {
          let newValue = value;
          if (value.includes(null) && value.length > 1) {
            if (value.findIndex(v => v === null) === 0) {
              newValue = value.filter(v => v !== null);
            } else {
              newValue = [null];
            }
            form.change('location', newValue);
          }
          if (newValue.length === 0) {
            newValue = [null];
            form.change('location', newValue);
          }
          setCountry(newValue);
        }}
          </OnChange>
          <div className={styles.root}>
            <Image src={getFlag(values.location)} alt={`${getTitle(values.location)} country flag`} width={48} height={48} className={styles.icon} />
            <div className={styles.info}>
              <Typography variant="h9" color="primary" className={styles.text}>
                {!values.location.every(c => c === null) && t('country.inPrefix.prefix')}{' '}
                {getTitle(values.location)}
              </Typography>
              <Typography variant="paragraph12" className={styles.text}>
                {t('country.description')}
              </Typography>
            </div>
            <DropDownButton anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }} transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }} classes={{
          paper: styles.dropdown
        }} trigger={<Button variant="secondary" className={styles.button} rounded>
                  <DownIcon className={styles.buttonIcon} />
                </Button>} dropdown={<CountriesList countries={countries as TCountry[]} isLoading={false} />} />
          </div>
        </>}
    </Form>;
};
export default CountryButton;