import { PopupState } from 'material-ui-popup-state/hooks';
import { bindHover } from 'material-ui-popup-state';
import React, { useCallback, useRef } from 'react';

/* eslint-disable react-hooks/rules-of-hooks,import/prefer-default-export */
export function bindDelayedHover(popupState: PopupState, delayMs = 200) {
  const { onTouchStart, onMouseOver, onMouseLeave, ...hoverAriaProps } = bindHover(popupState);

  const timeout = useRef<NodeJS.Timeout | null>(null);

  const delayedMouseOver = useCallback(
    (e: React.MouseEvent) => {
      if (timeout.current) clearTimeout(timeout.current);

      const { currentTarget } = e;
      timeout.current = setTimeout(() => {
        e.currentTarget = currentTarget;
        onMouseOver(e);
      }, delayMs);
    },
    [onMouseOver]
  );

  const handleMouseLeave = useCallback(
    (e: React.MouseEvent) => {
      if (timeout.current) clearTimeout(timeout.current);
      onMouseLeave(e);
    },
    [onMouseLeave]
  );

  return {
    onTouchStart,
    onMouseOver: delayedMouseOver,
    onMouseLeave: handleMouseLeave,
    ...hoverAriaProps,
  };
}
