'use client';

import React, { useCallback, useContext, useMemo } from 'react';
import { scaleLinear } from '@visx/scale';
import { useTooltip, useTooltipInPortal } from '@visx/tooltip';
import { useTranslations } from 'next-intl';
import { useParams } from 'next/navigation';
import capitalize from 'lodash/capitalize';
import Ukraine from 'components/Chart/Map/Ukraine';
import Typography from 'components/Typography';
import Divider from 'components/Divider';
import useScreenSizeDetect from 'hooks/useScreenSizeDetect';
import TooltipContainer from 'components/Chart/TooltipContainer';
import formatThousands from 'utils/formatThousands';
import { useRouter } from 'utils/navigation';
import { getRegionsWithCities } from 'serverActions/home';
import { ConfigurationContext } from 'contexts/ConfigurationContext';
import CitiesList from './List';
import styles from './Map.module.scss';
interface MapProps {
  cities: Awaited<ReturnType<typeof getRegionsWithCities>>;
}
const cityOpacityScale = scaleLinear({
  range: [0.1, 1]
});
const regionsOpacityScale = scaleLinear({
  range: [0.1, 1]
});
const Map: React.FC<MapProps> = ({
  cities
}) => {
  const t = useTranslations();
  const {
    isUkraineGeo
  } = useContext(ConfigurationContext);
  const params = useParams();
  const router = useRouter();
  const {
    isDesktop
  } = useScreenSizeDetect();
  const {
    containerRef,
    TooltipInPortal
  } = useTooltipInPortal({
    scroll: true,
    detectBounds: true
  });
  const tooltip = useTooltip<(typeof cities)['0'] & {
    properties: unknown;
  }>({
    tooltipOpen: false
  });
  cityOpacityScale.domain([0, Math.max(...cities.filter(city => !city.isRegion).map(city => city.channelsCount))]);
  regionsOpacityScale.domain([0, Math.max(...cities.filter(city => city.isRegion).map(city => city.channelsCount))]);
  const citiesById = useMemo(() => cities.reduce<Record<string, Awaited<ReturnType<typeof getRegionsWithCities>>['0']>>((acc, city) => {
    acc[city.iso] = city;
    if (!city.isRegion) {
      acc[city.name] = city;
    }
    return acc;
  }, {}), [cities]);
  const handleTooltipChange = useCallback((id: string, properties: unknown) => (event: React.TouchEvent<SVGPathElement> | React.MouseEvent<SVGPathElement>) => {
    if (!isDesktop) {
      return;
    }
    const entity = citiesById[id];
    const pathElement = event.target as SVGPathElement;
    const bbox = pathElement.getBBox();
    const centerX = bbox.x + bbox.width / 2;
    const centerY = bbox.y + bbox.height / 2;
    tooltip.showTooltip({
      tooltipData: {
        ...entity,
        properties
      },
      tooltipLeft: centerX,
      tooltipTop: centerY
    });
  }, [tooltip, citiesById, isDesktop]);
  const handleCityClick = useCallback((id: string) => {
    const city = citiesById[id];
    router.push({
      // @ts-ignore
      pathname: city.isRegion ? '/region/[id]' : '/city/[id]',
      params: {
        id: city.iso
      }
    });
  }, [citiesById, router]);
  if (!isUkraineGeo) {
    return null;
  }
  return <>
      <div className={styles.root}>
        <div className={styles.map}>
          <Typography variant="h2bk" component="h2" className={styles.title} data-sentry-element="Typography" data-sentry-source-file="Map.tsx">
            {t('Home.cities.title')}{' '}
            <span className={styles.country}>{t('Cities.country.uk')}</span>
          </Typography>
          <div ref={containerRef}>
            <Ukraine withCities cityOpacityScale={city => cityOpacityScale(citiesById[city].channelsCount ?? 0)} regionOpacityScale={region => regionsOpacityScale(citiesById[region].channelsCount ?? 0)} onCityHover={handleTooltipChange} onRegionHover={handleTooltipChange} onMouseLeave={tooltip.hideTooltip} onCityClick={handleCityClick} onRegionClick={handleCityClick} data-sentry-element="Ukraine" data-sentry-source-file="Map.tsx" />
          </div>
        </div>
        <div className={styles.list}>
          <CitiesList cities={cities} data-sentry-element="CitiesList" data-sentry-source-file="Map.tsx" />
        </div>
      </div>
      <Divider className={styles.divider} data-sentry-element="Divider" data-sentry-source-file="Map.tsx" />
      {tooltip.tooltipOpen && tooltip.tooltipData && <TooltipContainer left={tooltip.tooltipLeft ?? 0} top={tooltip.tooltipTop ?? 0} Component={TooltipInPortal} className={styles.tooltip} adaptive={false}>
          <Typography variant="h8">
            {/* @ts-ignore */}
            {tooltip.tooltipData.properties[`name:${params.locale as 'uk' | 'en'}`]}
          </Typography>
          <div className={styles.grid}>
            <Typography variant="h10">
              {capitalize(t('channelsCount', {
            count: 0
          }))}
              :
            </Typography>
            <Typography variant="h10bk" color="primary">
              {formatThousands(tooltip.tooltipData.channelsCount ?? 0)}
            </Typography>
            <Typography variant="h10">
              {capitalize(t('usersCount', {
            count: 0
          }))}
              :
            </Typography>
            <Typography variant="h10bk" color="primary">
              {formatThousands(tooltip.tooltipData.usersCount ?? 0)}
            </Typography>
          </div>
        </TooltipContainer>}
    </>;
};
export default Map;