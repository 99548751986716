import React, { HTMLAttributes } from 'react';
import cx from 'classnames';
import DateTimePlaceholder from 'components/DateTime/Placeholder';
import Divider from 'components/Divider';
import styles from './Placeholder.module.scss';
export const Placeholder: React.FC<HTMLAttributes<HTMLDivElement> & {
  withPaper?: boolean;
}> = ({
  className,
  withPaper
}) => <div className={cx(styles.card, className, {
  [styles.paper]: withPaper
})} data-sentry-component="Placeholder" data-sentry-source-file="Placeholder.tsx">
    <div className={styles.gallery} />
    <div className={styles.content}>
      <div className={styles.actions}>
        <DateTimePlaceholder data-sentry-element="DateTimePlaceholder" data-sentry-source-file="Placeholder.tsx" />
        <div className={styles.action} />
        <div className={styles.action} />
      </div>
      <div className={styles.text} />
      <div className={styles.text} />
      <div className={styles.text} />
      <div className={styles.text} />
      <div className={styles.text} />
      <div className={styles.text} />
    </div>
    <Divider className={styles.divider} data-sentry-element="Divider" data-sentry-source-file="Placeholder.tsx" />
    <div className={styles.metrics}>
      <div className={styles.metric} />
      <div className={styles.metric} />
      <div className={styles.metric} />
      <div className={styles.metric} />
    </div>
  </div>;
export default Placeholder;