'use client';

import React, { HTMLAttributes } from 'react';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import cx from 'classnames';
import Button from 'components/Button';
import Typography from 'components/Typography';
import { ReactComponent as SearchIcon } from 'public/assets/icons/search.svg';
import backgroundImage from 'public/assets/background-home-gradient-full-tiny.png';
import metricsExampleImage from 'public/assets/metrics-example.png';
import styles from './GeneralInfo.module.scss';
const renderHighlight = (text: React.ReactNode): React.ReactNode => <span className={styles.highlight} data-sentry-component="renderHighlight" data-sentry-source-file="GeneralInfo.tsx">{text}</span>;
const GeneralInfo: React.FC<HTMLAttributes<HTMLDivElement>> = ({
  className
}) => {
  const t = useTranslations('Home');
  const handleFocusSearch = () => {
    const searchInput = document.querySelector('input#search') as HTMLInputElement;
    const activator = document.querySelector('#search-activator') as HTMLButtonElement;
    if (searchInput) {
      searchInput.focus();
      const event = document.createEvent('HTMLEvents');
      event.initEvent('click', true, false);
      activator.dispatchEvent(event);
    }
  };
  return <div className={cx(styles.root, className)} data-sentry-component="GeneralInfo" data-sentry-source-file="GeneralInfo.tsx">
      <Image className={styles.background} placeholder="blur" src={backgroundImage} quality={100} alt="General info background" fill data-sentry-element="Image" data-sentry-source-file="GeneralInfo.tsx" />
      <Typography variant="h1bk" color="white" align="center" className={styles.title} component="h1" data-sentry-element="Typography" data-sentry-source-file="GeneralInfo.tsx">
        {t.rich('banner.text', {
        highlight: renderHighlight
      })}
      </Typography>
      <Typography variant="paragraph10" align="center" className={styles.description} component="h2" data-sentry-element="Typography" data-sentry-source-file="GeneralInfo.tsx">
        {t('banner.description')}
      </Typography>
      <Button variant="light" size="large" startIcon={<SearchIcon />} onClick={handleFocusSearch} data-sentry-element="Button" data-sentry-source-file="GeneralInfo.tsx">
        {t('search.button')}
      </Button>
      <div className={styles.imageWrapper}>
        <Image src={metricsExampleImage} alt="Metrics example" placeholder="blur" width={720} height={335} data-sentry-element="Image" data-sentry-source-file="GeneralInfo.tsx" />
      </div>
    </div>;
};
export default GeneralInfo;