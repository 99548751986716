/* eslint-disable import/namespace */

'use client';

import React, { HTMLAttributes, MouseEvent, useMemo, FunctionComponent } from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import { usePopupState } from 'material-ui-popup-state/hooks';
import InputAdornment from '@mui/material/InputAdornment';
import { useTranslations } from 'next-intl';
import type { TTopic } from 'business/application/repository/types.d';
import CategoryOverview from 'features/Popovers/CategoryOverview';
import Paper from 'components/Paper';
import Button from 'components/Button';
import Text from 'components/Input/Text';
import Divider from 'components/Divider';
import Typography from 'components/Typography';
import { Link } from 'utils/navigation';
import { bindDelayedHover } from 'utils/bindDelayedHover';
import { ReactComponent as InfoIcon } from 'public/assets/icons/info.svg';
import { ReactComponent as SearchIcon } from 'public/assets/icons/search.svg';
import { ReactComponent as RightArrowIcon } from 'public/assets/icons/arrow-r.svg';
import * as CategoryIcon from 'public/assets/icons/categories';
import styles from './Categories.module.scss';
interface CategoriesProps extends HTMLAttributes<HTMLDivElement> {
  categories: TTopic[];
}
const filterCategories = (categories: (TTopic & {
  translation: string;
})[]) => (search: string) => {
  if (!search) return categories;
  return categories.filter(category => category.translation.toLowerCase().includes(search.toLowerCase()));
};
const Categories: React.FC<CategoriesProps> = ({
  categories,
  className
}) => {
  const t = useTranslations();
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'category-overview'
  });
  const [activeCategory, setActiveCategory] = React.useState<TTopic | null>(null);
  const popoverCallback = bindDelayedHover(popupState, 500);
  const getFilteredCategories = useMemo(
  // @ts-ignore
  () => filterCategories(categories.map(c => ({
    ...c,
    translation: t(`categories.${c.id}`)
  }))), [categories, t]);
  const handleMouseOver = (option: TTopic, e: MouseEvent) => {
    setActiveCategory(option);
    popoverCallback.onMouseOver(e);
  };
  const handleMouseLeave = (e: React.MouseEvent) => {
    popoverCallback.onMouseLeave(e);
  };
  return <Paper className={className} data-sentry-element="Paper" data-sentry-component="Categories" data-sentry-source-file="Categories.tsx">
      <div className={styles.root}>
        <Typography variant="h10" className={styles.title} data-sentry-element="Typography" data-sentry-source-file="Categories.tsx">
          {t('Home.categories.title')}
        </Typography>
        <Form onSubmit={() => {}} subscription={{
        submitting: true
      }} data-sentry-element="Form" data-sentry-source-file="Categories.tsx">
          {() => <>
              <Field name="search" component={Text} placeholder={t('Home.categories.searchPlaceholder')} classes={{
            root: styles.search,
            input: styles.searchInput
          }} InputProps={{
            startAdornment: <InputAdornment position="start" disablePointerEvents={false} className={styles.adornment}>
                      <SearchIcon className={styles.searchIcon} />
                    </InputAdornment>
          }} />
              <div className={styles.list}>
                <FormSpy subscription={{
              values: true
            }}>
                  {({
                values
              }) => getFilteredCategories(values?.search).map(category => <Link href={{
                pathname: '/topic/[id]',
                params: {
                  id: category.id
                }
              }} key={category.id} className={styles.category} onMouseOver={e => handleMouseOver(category, e)} onMouseLeave={handleMouseLeave}>
                        <div className={styles.icon}>
                          {/* @ts-ignore */}
                          {typeof CategoryIcon[category.id] !== 'undefined' ? React.createElement(CategoryIcon[category.id as keyof typeof CategoryIcon] as FunctionComponent, {
                    // @ts-ignore
                    style: {
                      '--svg-color': 'var(--font-secondary-color)'
                    }
                  }) : <InfoIcon className={styles.fallback} />}
                        </div>
                        {/* @ts-ignore */}
                        <Typography variant="h10bk">{t(category.title)}</Typography>
                      </Link>)}
                </FormSpy>
              </div>
            </>}
        </Form>
        <Divider data-sentry-element="Divider" data-sentry-source-file="Categories.tsx" />
        <Button href="/topics" variant="text" size="medium" className={styles.button} component={Link} data-sentry-element="Button" data-sentry-source-file="Categories.tsx">
          {t('Home.showAll')}
          <RightArrowIcon data-sentry-element="RightArrowIcon" data-sentry-source-file="Categories.tsx" />
        </Button>
      </div>
      {activeCategory && <CategoryOverview popupState={popupState} activeCategory={activeCategory} />}
      <Button href="/topics" component={Link} variant="primary" size="large" className={styles.all} data-sentry-element="Button" data-sentry-source-file="Categories.tsx">
        {t('Home.categories.all')}
      </Button>
    </Paper>;
};
export default Categories;