import React, { HTMLAttributes, useEffect } from 'react';
import cx from 'classnames';
// @ts-ignore
import shortNumber from 'short-number';
import { useTranslations } from 'next-intl';
import { getChannelsCatalogue } from 'serverActions/catalogue/channels';
import ChannelAvatar from 'components/Avatar/Channel';
import Typography from 'components/Typography';
import Button from 'components/Button';
import Tag from 'components/Tag';
import { useRouter } from 'utils/navigation';
import formatThousands from 'utils/formatThousands';
import { ReactComponent as OpenIcon } from 'public/assets/icons/open.svg';
import { ReactComponent as UserIcon } from 'public/assets/icons/user.svg';
import styles from './ChannelCard.module.scss';
interface ChannelCardProps extends HTMLAttributes<HTMLDivElement> {
  channel: Awaited<ReturnType<typeof getChannelsCatalogue>>['channels']['0'];
  icon: React.JSX.Element;
  metric: number | string;
  members: number;
  hideMembersCount?: boolean;
  highlightMetric?: boolean;
}
const ChannelCard: React.FC<ChannelCardProps> = ({
  channel,
  icon,
  metric,
  members,
  className,
  hideMembersCount,
  highlightMetric
}) => {
  const t = useTranslations();
  const renderMembers = members > 1000000 ? shortNumber(members) : formatThousands(members);
  const [buttonRef, setButtonRef] = React.useState<HTMLDivElement | null>(null);
  const router = useRouter();
  useEffect(() => {
    buttonRef?.querySelectorAll('a,button').forEach(el => {
      el.addEventListener('click', e => {
        e.stopPropagation();
      });
    });
  }, [buttonRef]);
  const handleClick = () => {
    router.push({
      pathname: '/channel/[id]',
      params: {
        id: channel.id
      }
    }, {
      scroll: true
    });
  };
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div className={cx(styles.root, className)} ref={setButtonRef} onClick={handleClick}
    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
    tabIndex={0} data-sentry-component="ChannelCard" data-sentry-source-file="ChannelCard.tsx">
      <div className={styles.info}>
        <ChannelAvatar size="48" src={channel?.profileImage?.url} alt={`Channel ${channel.title} avatar`} name={channel.title} verified={!!channel.isVerified} className={styles.avatar} data-sentry-element="ChannelAvatar" data-sentry-source-file="ChannelCard.tsx" />
        <Typography variant="h9" className={styles.title} color="primary" data-sentry-element="Typography" data-sentry-source-file="ChannelCard.tsx">
          {channel.title}
        </Typography>
        <Button variant="text" rounded className={styles.link} href={channel.telegramUrl} target="_blank" rel="noreferrer noopener" data-sentry-element="Button" data-sentry-source-file="ChannelCard.tsx">
          <OpenIcon data-sentry-element="OpenIcon" data-sentry-source-file="ChannelCard.tsx" />
        </Button>
        <div className={styles.details}>
          {!hideMembersCount && <Typography variant="h12bk" className={styles.members}>
              <UserIcon className={styles.membersIcon} /> {renderMembers}
            </Typography>}
          {channel.category?.map(category => <Tag key={category.id} typographyProps={{
            className: styles.tag
          }}>
              {/* @ts-ignore */}
              {t(`categories.${category.id}`)}
            </Tag>)}
        </div>
      </div>
      <div className={cx(styles.metric, {
        [styles.highlight]: highlightMetric
      })}>
        {React.cloneElement(icon, {
          className: styles.metricIcon
        })}
        <Typography variant="h10bk" className={styles.metricValue} data-sentry-element="Typography" data-sentry-source-file="ChannelCard.tsx">
          {formatThousands(metric)}
        </Typography>
      </div>
    </div>
  );
};
export default ChannelCard;