import React, { useContext } from 'react';
import useSWR from 'swr';
import { bindPopover } from 'material-ui-popup-state';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import { PopupState } from 'material-ui-popup-state/hooks';
import { useTranslations } from 'next-intl';
import Button from 'components/Button';
import Typography from 'components/Typography';
import ChannelAvatar from 'components/Avatar/Channel';
import formatThousands from 'utils/formatThousands';
import { Link } from 'utils/navigation';
import { getCategoryPreview } from 'serverActions/catalogue/channels';
import { ReactComponent as UserIcon } from 'public/assets/icons/user.svg';
import { TTopic } from 'business/application/repository';
import { ConfigurationContext } from 'contexts/ConfigurationContext';
import styles from './CategoryOverview.module.scss';
interface PopoverProps {
  popupState: PopupState;
  activeCategory: TTopic;
}
const Popover: React.FC<PopoverProps> = ({
  popupState,
  activeCategory
}) => {
  const t = useTranslations();
  const {
    countriesFilter
  } = useContext(ConfigurationContext);
  const {
    data,
    isLoading
  } = useSWR(`/catalogue/category/${activeCategory.id}/${countriesFilter}`, () => getCategoryPreview(activeCategory.id, countriesFilter), {
    revalidateOnReconnect: false,
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnMount: false
  });
  if (!activeCategory) return null;
  return <HoverPopover disableScrollLock {...bindPopover(popupState)} anchorOrigin={{
    vertical: 'top',
    horizontal: 'right'
  }} transformOrigin={{
    vertical: 'top',
    horizontal: 'left'
  }} PaperProps={{
    className: styles.popover
  }} data-sentry-element="HoverPopover" data-sentry-component="Popover" data-sentry-source-file="CategoryOverview.tsx">
      <Typography variant="h9" color="primary" data-sentry-element="Typography" data-sentry-source-file="CategoryOverview.tsx">
        {/* @ts-ignore */}
        {t(activeCategory.title)}
      </Typography>
      <Typography variant="paragraph12" component="p" className={styles.description} data-sentry-element="Typography" data-sentry-source-file="CategoryOverview.tsx">
        {/* @ts-ignore */}
        {t(activeCategory.description)}
      </Typography>
      <div className={styles.channels}>
        {isLoading && <>
            <div className={styles.placeholder}>
              <div className={styles.avatar} />
              <div className={styles.title} />
              <div className={styles.members} />
            </div>
            <div className={styles.placeholder}>
              <div className={styles.avatar} />
              <div className={styles.title} />
              <div className={styles.members} />
            </div>
            <div className={styles.placeholder}>
              <div className={styles.avatar} />
              <div className={styles.title} />
              <div className={styles.members} />
            </div>
            <div className={styles.placeholder}>
              <div className={styles.avatar} />
              <div className={styles.title} />
              <div className={styles.members} />
            </div>
          </>}
        {data?.map(channel => <Button key={channel.id} variant="text" component={Link} href={{
        pathname: '/channel/[id]',
        params: {
          id: channel.id
        }
      }} className={styles.channel}>
            <ChannelAvatar size="48" name={channel.title} src={channel?.profileImage?.url} alt={`${channel.title} avatar`} />
            <Typography variant="h10" className={styles.title} color="primary">
              {channel.title}
            </Typography>
            <div className={styles.members}>
              <UserIcon />
              <Typography variant="h12bk" className={styles.members}>
                {formatThousands(channel.members)}
              </Typography>
            </div>
          </Button>)}
      </div>
    </HoverPopover>;
};
export default Popover;