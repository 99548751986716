'use client';

import React, { useContext, useMemo, useRef, useState } from 'react';
import useSWRInfinite, { SWRInfiniteConfiguration } from 'swr/infinite';
import { useTranslations } from 'next-intl';
import cx from 'classnames';
// @ts-ignore
import shortNumber from 'short-number';
import { getChannels } from 'serverActions/home';
import Button from 'components/Button';
import Paper from 'components/Paper';
import InfiniteScroller, { Scroller } from 'components/InfiniteScroller';
import { Link } from 'utils/navigation';
import { MetricType } from 'business/channel/repository/types.d';
import { ConfigurationContext } from 'contexts/ConfigurationContext';
import { ReactComponent as ArrowRightIcon } from 'public/assets/icons/arrow-r.svg';
import { ReactComponent as ArrowLeftIcon } from 'public/assets/icons/arrow-l.svg';
import Typography from 'components/Typography';
import ChannelCard from './ChannelCard';
import ChannelCardPlaceholder from './ChannelCardPlaceholder';
import styles from './ChannelsListInfinite.module.scss';
interface ChannelsListInfiniteProps {
  title: React.ReactNode;
  empty: string;
  uniqueKey: string;
  initialData?: SWRInfiniteConfiguration<Awaited<ReturnType<typeof getChannels>>>['fallback'];
  additionalFilters?: Record<string, string>;
  metrics?: MetricType[];
  showAllLink?: boolean;
  withCounter?: boolean;
  predefineGeo?: string;
  ignoreGeo?: boolean;
  hideOnEmpty?: boolean;
  className?: string;
}
const ChannelsListInfinite: React.FC<ChannelsListInfiniteProps> = ({
  title,
  empty,
  uniqueKey,
  showAllLink = false,
  withCounter = false,
  initialData,
  additionalFilters,
  metrics,
  className,
  predefineGeo,
  ignoreGeo = false,
  hideOnEmpty = false
}) => {
  const t = useTranslations();
  const [isScrolledToEndState, setIsScrolledToEnd] = useState(false);
  const {
    countriesFilter
  } = useContext(ConfigurationContext);
  const scroller = useRef<Scroller>(null);
  const {
    data,
    isValidating,
    setSize,
    size
  } = useSWRInfinite(index => ({
    entity: `channelsWithFilters/${uniqueKey}`,
    ...(!ignoreGeo && {
      location: predefineGeo || countriesFilter
    }),
    page: index + 1
  }), ({
    page,
    location
  }) => getChannels(page, {
    ...additionalFilters,
    ...(location && {
      location
    })
  }, metrics), {
    initialSize: 1,
    revalidateOnFocus: false,
    revalidateIfStale: false,
    revalidateOnReconnect: false,
    fallback: initialData,
    ...(typeof initialData !== 'undefined' && {
      revalidateOnMount: false,
      revalidateFirstPage: false
    })
  });
  const {
    channels,
    total
  } = useMemo(() => {
    if (data) {
      return {
        channels: data.flatMap(page => {
          return page ? page.channels : [];
        }),
        total: data[0]?.total || 0
      };
    }
    return {
      channels: [],
      total: 0
    };
  }, [data]);
  const getNextChannels = async () => {
    if (channels.length < total && !isValidating) {
      return setSize(size + 1);
    }
    return undefined;
  };
  const handleNextSlide = () => {
    scroller.current?.scrollNext();
  };
  const handlePrevSlide = () => {
    scroller.current?.scrollBack();
  };
  if (hideOnEmpty && !channels.length && !isValidating) {
    return null;
  }
  return <div className={cx(styles.root, className)} data-sentry-component="ChannelsListInfinite" data-sentry-source-file="ChannelsListInfinite.tsx">
      <div className={styles.title}>
        {title}
        {withCounter && <Typography component="sup" variant="h12bk" color="white" className={styles.channelsCount}>
            {shortNumber(total)}&nbsp;
            {t('channelsCount', {
          count: total
        })}
          </Typography>}
      </div>
      <div className={styles.controls}>
        <Button variant="secondary" rounded size="large" onClick={handlePrevSlide} className={styles.slider} data-sentry-element="Button" data-sentry-source-file="ChannelsListInfinite.tsx">
          <ArrowLeftIcon data-sentry-element="ArrowLeftIcon" data-sentry-source-file="ChannelsListInfinite.tsx" />
        </Button>
        <Button variant="secondary" rounded size="large" onClick={handleNextSlide} className={styles.slider} data-sentry-element="Button" data-sentry-source-file="ChannelsListInfinite.tsx">
          <ArrowRightIcon data-sentry-element="ArrowRightIcon" data-sentry-source-file="ChannelsListInfinite.tsx" />
        </Button>
        {showAllLink && <Button variant="primary" size="large" href={{
        pathname: '/channels',
        query: {
          ...additionalFilters,
          ...(countriesFilter && {
            location: countriesFilter
          })
        }
      }} component={Link}>
            {t('Home.showAll')}
          </Button>}
      </div>
      <Paper className={cx(styles.paper, {
      [styles.gradiented]: !isScrolledToEndState
    })} data-sentry-element="Paper" data-sentry-source-file="ChannelsListInfinite.tsx">
        {empty && channels.length === 0 && !isValidating && <div className={styles.empty}>
            <Typography variant="h6sb">{empty}</Typography>
          </div>}
        <InfiniteScroller scrollerRef={scroller} isLoading={isValidating} direction="horizontal" items={channels} component={ChannelCard} uniqItemKey="id" loadNext={getNextChannels} total={total} placeholder={ChannelCardPlaceholder} placeholderCount={4} itemAsProps="channel" classes={{
        item: styles.item
      }} onScroll={scrollParams => {
        const {
          scrollLeft,
          clientWidth,
          scrollWidth
        } = scrollParams;
        const isScrolledToEnd = Math.floor(scrollWidth - scrollLeft) === clientWidth;
        if (isScrolledToEnd !== isScrolledToEndState) {
          setIsScrolledToEnd(isScrolledToEnd);
        }
      }} data-sentry-element="InfiniteScroller" data-sentry-source-file="ChannelsListInfinite.tsx" />
      </Paper>
    </div>;
};
export default ChannelsListInfinite;