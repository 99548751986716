import React, { useEffect, useState } from 'react';
import { useTranslations } from 'next-intl';
import { MetricType } from 'business/channel/repository/types.d';
import { getChannels } from 'serverActions/home';
import Typography from 'components/Typography';
import ChannelAvatar from 'components/Avatar/Channel';
import Button from 'components/Button';
import { ReactComponent as OpenIcon } from 'public/assets/icons/open.svg';
import { ReactComponent as UserIcon } from 'public/assets/icons/user.svg';
import formatThousands from 'utils/formatThousands';
import Tag from 'components/Tag';
import { useRouter } from 'utils/navigation';
import styles from './ChannelCard.module.scss';
interface ChannelCardProps {
  channel: Awaited<ReturnType<typeof getChannels>>['channels']['0'];
  index: number;
}
const ChannelCard: React.FC<ChannelCardProps> = ({
  channel,
  index
}) => {
  const t = useTranslations();
  const [cardRef, setCardRef] = useState<HTMLDivElement | null>(null);
  const router = useRouter();
  useEffect(() => {
    if (cardRef) {
      cardRef.querySelectorAll('a').forEach(a => {
        a.addEventListener('click', e => e.stopPropagation());
      });
    }
  }, [cardRef]);
  const handleClick = () => {
    router.push({
      pathname: '/channel/[id]',
      params: {
        id: channel.id
      }
    });
  };
  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div className={styles.root} ref={setCardRef} onClick={handleClick} tabIndex={0} data-sentry-component="ChannelCard" data-sentry-source-file="ChannelCard.tsx">
      <div>
        <Typography variant="h10" color="primary" data-sentry-element="Typography" data-sentry-source-file="ChannelCard.tsx">
          {index + 1}
        </Typography>
        <Typography variant="h10" data-sentry-element="Typography" data-sentry-source-file="ChannelCard.tsx">#</Typography>
      </div>
      <div className={styles.channel}>
        <ChannelAvatar size="64" name={channel.title} src={channel?.profileImage?.url} alt={`Channel ${channel.title} avatar`} verified={!!channel.isVerified} className={styles.avatar} data-sentry-element="ChannelAvatar" data-sentry-source-file="ChannelCard.tsx" />
        <div className={styles.title}>
          <Typography variant="h9" color="primary" className={styles.ellipsis} data-sentry-element="Typography" data-sentry-source-file="ChannelCard.tsx">
            {channel.title}
          </Typography>
          <Button variant="text" rounded className={styles.link} href={channel.telegramUrl} target="_blank" rel="noreferrer noopener" data-sentry-element="Button" data-sentry-source-file="ChannelCard.tsx">
            <OpenIcon data-sentry-element="OpenIcon" data-sentry-source-file="ChannelCard.tsx" />
          </Button>
        </div>
        <div className={styles.additional}>
          <Typography variant="h12bk" className={styles.members} data-sentry-element="Typography" data-sentry-source-file="ChannelCard.tsx">
            <UserIcon className={styles.icon} data-sentry-element="UserIcon" data-sentry-source-file="ChannelCard.tsx" />
            {formatThousands(channel.metrics[MetricType.MembersCount].absolute)}
          </Typography>
          {channel.category?.map(category =>
          // @ts-ignore
          <Tag key={category.id}>{t(`categories.${category.id}`)}</Tag>)}
        </div>
      </div>
    </div>
  );
};
export default ChannelCard;