'use client';

import React, { useEffect, useMemo, useRef, useState, useContext } from 'react';
import useSWRInfinite from 'swr/infinite';
import { useTranslations } from 'next-intl';
import { TPost } from 'business/channel/repository/types.d';
import { getPopularPosts } from 'serverActions/home';
import { Link } from 'utils/navigation';
import Button from 'components/Button';
import Typography from 'components/Typography';
import InfiniteScroller, { Scroller } from 'components/InfiniteScroller';
import PostVerticalWithEmojiTemplate from 'features/PostTemplate/VerticalWithEmoji';
import PostVerticalWithEmojiPlaceholder from 'features/PostTemplate/VerticalWithEmoji/Placeholder';
import { ConfigurationContext } from 'contexts/ConfigurationContext';
import { ReactComponent as ArrowLeftIcon } from 'public/assets/icons/arrow-l.svg';
import { ReactComponent as ArrowRightIcon } from 'public/assets/icons/arrow-r.svg';
import styles from './PopularPosts.module.scss';
interface PopularPostsProps {
  initialValues: Awaited<ReturnType<typeof getPopularPosts>>;
}
const PopularPosts: React.FC<PopularPostsProps> = ({
  initialValues
}) => {
  const t = useTranslations('Home');
  const {
    countriesFilter
  } = useContext(ConfigurationContext);
  const [initiated, setInitiated] = useState(false);
  const scroller = useRef<Scroller>(null);
  const {
    data,
    size,
    setSize,
    isValidating
  } = useSWRInfinite(index => {
    return {
      entity: `home/popularPosts`,
      location: countriesFilter,
      page: index + 1
    };
  }, ({
    page,
    location
  }) => getPopularPosts(page, location ? {
    location
  } : undefined), {
    initialSize: 1,
    revalidateOnFocus: false,
    revalidateIfStale: false,
    revalidateOnReconnect: false
  });
  const {
    posts,
    total
  } = useMemo(() => {
    if (data) {
      return {
        posts: data.flatMap(page => {
          return page ? page.posts.slice(0, 15) : [];
        }),
        total: data[0]?.total || 0
      };
    }
    return {
      posts: [],
      total: 0
    };
  }, [data]);
  useEffect(() => {
    if (!initiated && data) {
      setInitiated(true);
    }
  }, [initiated, data]);
  const dataForRender = initiated ? {
    posts,
    total
  } : initialValues;
  const getNextPosts = async () => {
    if (posts.length < total && !isValidating) {
      return setSize(size + 1);
    }
    return undefined;
  };
  const handleNextSlide = () => {
    scroller.current?.scrollNext();
  };
  const handlePrevSlide = () => {
    scroller.current?.scrollBack();
  };
  return <div className={styles.root} data-sentry-component="PopularPosts" data-sentry-source-file="PopularPosts.tsx">
      <div className={styles.title}>
        <Typography variant="h2bk" className={styles.ellipsis} data-sentry-element="Typography" data-sentry-source-file="PopularPosts.tsx">
          {t('popularPosts.title')}
        </Typography>
      </div>
      <div className={styles.controls}>
        <Button variant="secondary" rounded size="large" onClick={handlePrevSlide} className={styles.slider} data-sentry-element="Button" data-sentry-source-file="PopularPosts.tsx">
          <ArrowLeftIcon data-sentry-element="ArrowLeftIcon" data-sentry-source-file="PopularPosts.tsx" />
        </Button>
        <Button variant="secondary" rounded size="large" onClick={handleNextSlide} className={styles.slider} data-sentry-element="Button" data-sentry-source-file="PopularPosts.tsx">
          <ArrowRightIcon data-sentry-element="ArrowRightIcon" data-sentry-source-file="PopularPosts.tsx" />
        </Button>
        <Button variant="primary" size="large" href={{
        pathname: '/posts',
        query: {
          sort: 'popularity',
          order: 'desc',
          ...{
            ...(countriesFilter ? {
              location: countriesFilter
            } : {})
          }
        }
      }} component={Link} data-sentry-element="Button" data-sentry-source-file="PopularPosts.tsx">
          {t('showAll')}
        </Button>
      </div>
      <InfiniteScroller<TPost & {
      channelId: string;
    }> isLoading={initiated && isValidating} scrollerRef={scroller} direction="horizontal" total={dataForRender.total} items={dataForRender.posts} component={PostVerticalWithEmojiTemplate} loadNext={getNextPosts} uniqItemKey="id" itemAsProps="post" itemProps={{
      withPaper: true
    }} placeholder={PostVerticalWithEmojiPlaceholder} classes={{
      root: styles.scroller,
      item: styles.item
    }} data-sentry-element="InfiniteScroller" data-sentry-source-file="PopularPosts.tsx" />
    </div>;
};
export default PopularPosts;