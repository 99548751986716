import React from 'react';
import styles from './ChannelCardPlaceholder.module.scss';
const ChannelCardPlaceholder: React.FC = () => {
  return <div className={styles.root} data-sentry-component="ChannelCardPlaceholder" data-sentry-source-file="ChannelCardPlaceholder.tsx">
      <div className={styles.top}>
        <div className={styles.rating} />
      </div>
      <div className={styles.channel}>
        <div className={styles.avatar} />
        <div className={styles.title} />
        <div className={styles.additional}>
          <div className={styles.members} />
          <div className={styles.category} />
        </div>
      </div>
    </div>;
};
export default ChannelCardPlaceholder;